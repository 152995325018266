import React, { useState, useEffect, useContext, useCallback } from "react";
import Header from "../header/Header";
import { FilterMatchMode } from "primereact/api";
import { RelationshipProximity, RelationshipType } from "../../service/enums";
import AddRelationshipForm from "./AddRelationshipForm";
import EditRelationshipForm from "./EditRelationshipForm";
import SendSelfEmailForm from "./SendSelfEmailForm"
import SendSelfEmailCompletedForm from "./SendSelfEmailCompletedForm"
import {
  RelationshipsState,
  createRelationship,
  deleteRelationship,
  loadRelationships,
  updateRelationship,
} from "../store_providers/relationships-store";
import ReflectorsLayout from "./ReflectorsLayout";
import ReflectorsContainer from "./ReflectorsContainer";
import DesktopDashboard from "./DesktopDashboard";
import { useScreenDetector } from "../../hooks/useScreenDetector";
import MobileDashboard from "./MobileDashboard";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { UserDataState } from "../store_providers/user-data-store";
import Loading from "../Loading";
import { getHasSentSelfEmailEndpoint } from "../../service/api-temp.js"
import { AppHttp } from "../../service/app-http";

function Dashboard() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [selfEmailButtonVisible, setSelfEmailButtonVisible] = useState(false);
  const [selfEmailDialogVisible, setSelfEmailDialogVisible] = useState(false);
  const [selfEmailConfirmDialogVisible, setSelfEmailConfirmDialogVisible] = useState(false);
  const [editingCircle, setEditingCircle] = useState({});
  const [circles, dispatch] = useContext(RelationshipsState);
  const { isMobile } = useScreenDetector();
  const [userData] = useContext(UserDataState);

  const [position, setPosition] = useState("center");

  const [countByType, setCountByType] = useState({
    [RelationshipType.Peer]: 0,
    [RelationshipType.Junior]: 0,
    [RelationshipType.Senior]: 0,
    [RelationshipType.Client]: 0,
  });

  const [lowestIdInType, setLowestIdInType] = useState({
    [RelationshipType.Peer]: 0,
    [RelationshipType.Junior]: 0,
    [RelationshipType.Senior]: 0,
    [RelationshipType.Client]: 0,
  });

  const types = [
    { id: RelationshipType.Peer, name: "Peer" },
    { id: RelationshipType.Junior, name: "Junior" },
    { id: RelationshipType.Senior, name: "Senior" },
    { id: RelationshipType.Client, name: "Client" },
  ];

  async function onRelationshipAddSubmit(values) {
    const newRelationship = await createRelationship(values);

    dispatch({ type: "add", payload: newRelationship });
    hideAndInc();
  }

  async function onRelationshipEditSubmit(values) {
    const updatedRelationship = await updateRelationship(values);

    dispatch({ type: "update", payload: updatedRelationship });
    setVisible2(false);
  }

  async function onDeleteRelationship(relationship) {
    const didDelete = await deleteRelationship(relationship.id);

    if (didDelete) {
      dispatch({ type: "delete", payload: relationship });
    }
    setVisible2(false);
  }

  async function getSelfMailStatus() {
    let hasSentSelfEmailReflection = false;
    try {
      const response = await AppHttp.get(getHasSentSelfEmailEndpoint);
      
      if (response.success) {
          hasSentSelfEmailReflection = response.data;
      }
      else {
        console.log("error!");
      }
    }
    catch {
      console.log("failed to get correct response from API!");
    }
    return hasSentSelfEmailReflection;
  }

  useEffect(() => {
    async function loadData() {
      const relationshipsFromApi = await loadRelationships();

      setLoading(false);

      if (relationshipsFromApi) {
        setCount(relationshipsFromApi.length);
        dispatch({ type: "set", payload: relationshipsFromApi });

        if (relationshipsFromApi && relationshipsFromApi.length < 7) {
          navigate("/dashboard/reflectors-setup");
        }
      }
    }
  
    if (!circles || circles.length === 0) {
      loadData();
    } else if (circles && circles.length < 7) {
      navigate("/dashboard/reflectors-setup");
    } else {
      setLoading(false)
    }
  }, [dispatch, circles, navigate]);

  useEffect(() => {
    async function loadHasSentSelfEmailState() {
      let hasSentSelfEmail = await getSelfMailStatus();
      setSelfEmailButtonVisible(!hasSentSelfEmail);
    }
     
    loadHasSentSelfEmailState();
  }, []);

  const positionCircle = useCallback(
    (circle) => {
      const oftenSquare = document.querySelector(".big-rectangle-1");
      const occasionallySquare = document.querySelector(".big-rectangle-2");
      const sporadicallySquare = document.querySelector(".big-rectangle-3");

      if (!oftenSquare || !occasionallySquare || !sporadicallySquare) {
        return;
      }

      let square = null;

      if (circle.relationshipProximity === RelationshipProximity.Often) {
        square = oftenSquare;
      } else if (
        circle.relationshipProximity === RelationshipProximity.Occasionally
      ) {
        square = occasionallySquare;
      } else if (
        circle.relationshipProximity === RelationshipProximity.Sporadically
      ) {
        square = sporadicallySquare;
      }

      const maxNumberOfCircles = Math.floor((square.clientWidth - 80) / 40);

      const numberOfReflectorsThisType = circles.reduce((prev, current) => {
        if (
          current.relationshipProximity === circle.relationshipProximity &&
          current.relationshipType === circle.relationshipType
        ) {
          return prev + 1;
        }

        return prev;
      }, 0);

      const circlesInPeer = circles.reduce((prev, current) => {
        if (current.id >= circle.id) {
          return prev;
        }

        if (
          current.relationshipProximity === circle.relationshipProximity &&
          current.relationshipType === circle.relationshipType
        ) {
          return prev + 1;
        }

        return prev;
      }, 0);

      const elWidth = square.clientWidth;
      const elHeight = square.clientHeight;
      let moveCircleBy = 50;

      if (numberOfReflectorsThisType > maxNumberOfCircles) {
        moveCircleBy = 20;
      }

      const el = document.querySelector(".circle-" + circle.id);

      if (circle.relationshipType === RelationshipType.Peer) {
        el.style.transform = `translateX(${-(elWidth / 2)}px) translateY(${-(elHeight / 2 - 40) + circlesInPeer * moveCircleBy
          }px)`;
      } else if (circle.relationshipType === RelationshipType.Client) {
        el.style.transform = `translateX(${elWidth / 2}px) translateY(${-(elHeight / 2 - 40) + circlesInPeer * moveCircleBy
          }px)`;
      } else if (circle.relationshipType === RelationshipType.Senior) {
        el.style.transform = `translateX(${-(elHeight / 2 - 40) + circlesInPeer * moveCircleBy
          }px) translateY(${-(elWidth / 2)}px)`;
      } else {
        el.style.transform = `translateX(${-(elHeight / 2 - 40) + circlesInPeer * moveCircleBy
          }px) translateY(${elWidth / 2}px)`;
      }

      el.style.display = "flex";
    },
    [circles]
  );

  const positionMobileCircle = useCallback(

    (circle) => {

      const el = document.querySelector(".circle-" + circle.id);
      if (!el) {
        return
      }
      let circlesInPeer = circles.reduce((prev, current) => {
        if (current.id >= circle.id) {
          return prev;
        }

        if (current.relationshipType === circle.relationshipType) {
          return prev + 1;
        }

        return prev;
      }, 0);

      if (circlesInPeer > 2) {
        circlesInPeer = 2
      }

      if (circle.relationshipType === RelationshipType.Peer) {
        el.style.transform = `translateX(${-112 + circlesInPeer * 7
          }px) translateY(0px)`;
      } else if (circle.relationshipType === RelationshipType.Client) {
        el.style.transform = `translateX(${112 + circlesInPeer * 7
          }px) translateY(0px)`;
      } else if (circle.relationshipType === RelationshipType.Senior) {
        el.style.transform = `translateX(${0 + circlesInPeer * 7
          }px) translateY(-112px)`;
      } else {
        el.style.transform = `translateX(${0 + circlesInPeer * 7
          }px) translateY(112px)`;
      }

      el.style.display = "flex";
    },
    [circles]
  );

  useEffect(() => {
    function positionThem() {
      if (circles) {
        circles.forEach((single) => {
          if (lowestIdInType[single.relationshipType] === 0) {
            setLowestIdInType((val) => ({
              ...val,
              [single.relationshipType]: single.id,
            }));
          }

          setCountByType((val) => ({
            ...val,
            [single.relationshipType]: val[single.relationshipType] + 1,
          }));

          if (isMobile) {
            positionMobileCircle(single);
          } else {
            positionCircle(single);
          }
        });
      }
    }
    positionThem();

    return () => {
      setCountByType({
        [RelationshipType.Peer]: 0,
        [RelationshipType.Junior]: 0,
        [RelationshipType.Senior]: 0,
        [RelationshipType.Client]: 0,
      });
    };
  }, [circles, positionCircle, positionMobileCircle, lowestIdInType, isMobile]);

  function setEditRelationship(relationship) {
    setEditingCircle(relationship);

    setVisible2(true);
  }

  let maxValue = 7;

  if (count >= maxValue) {
    maxValue = userData.reflectorsCap;
  }

  function hideAndInc() {
    setVisible(false);
    setCount(count + 1);
  }

  const [filters, setFilters] = useState({
    reflectorAlias: { value: null, matchMode: FilterMatchMode.CONTAINS },
    relationshipType: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onRelationshipTypeFilterChanged = (relationshipType) => {
    const _filters = { ...filters };

    _filters.relationshipType.value = relationshipType;

    setFilters(_filters);
  };

  const onClearRelationshipTypeFlter = () => {
    const _filters = { ...filters };

    _filters.relationshipType.value = null;

    setFilters(_filters);
  };

  const onReflectorAliasFilterChanged = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["reflectorAlias"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  function onCellClicked(e) {
    if (
      e?.value?.column?.props?.field === "reflectorAlias" ||
      e?.value?.column?.props?.field === "relationshipProximity"
    ) {
      setEditRelationship(e.value.rowData);
    }
  }

  return (
    <Loading visible={loading}>
      <ReflectorsLayout>
        <Header />
        <ReflectorsContainer>
          {isMobile ? (
            <MobileDashboard
              count={count}
              circles={circles}
              onCellClicked={onCellClicked}
              filters={filters}
              globalFilterValue={globalFilterValue}
              onGlobalFilterChange={onReflectorAliasFilterChanged}
              setEditRelationship={setEditRelationship}
              setVisible={setVisible}
              setVisible2={setVisible2}
              types={types}
              updateRelationship={updateRelationship}
              dispatch={dispatch}
              onClearRelationshipTypeFlter={onClearRelationshipTypeFlter}
              countByType={countByType}
              onReflectorAliasFilterChanged={onReflectorAliasFilterChanged}
              onRelationshipTypeFilterChanged={onRelationshipTypeFilterChanged}
              lowestIdInType={lowestIdInType}
              position={setPosition}
            />
          ) : (
            <DesktopDashboard
              count={count}
              circles={circles}
              onCellClicked={onCellClicked}
              filters={filters}
              globalFilterValue={globalFilterValue}
              onGlobalFilterChange={onReflectorAliasFilterChanged}
              setEditRelationship={setEditRelationship}
              setVisible={setVisible}
              setVisible2={setVisible2}
              setSelfEmailDialogVisible={setSelfEmailDialogVisible}
              selfEmailButtonVisible={selfEmailButtonVisible}
              setSelfEmailButtonVisible={setSelfEmailButtonVisible}
              types={types}
              updateRelationship={updateRelationship}
              dispatch={dispatch}
            />
          )}
        </ReflectorsContainer>
        <Dialog
          position={position}
          className="w-full max-w-lg p-input-filled text-center"
          header="Whose perspective matters to you?"
          headerStyle={{ paddingLeft: "4rem" }}
          visible={visible}
          modal={true}
          style={{ width: "40rem" }}
          onHide={() => setVisible(false)}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <AddRelationshipForm
            onSubmit={onRelationshipAddSubmit}
            allowEditingType={true}
          />
        </Dialog>
        <Dialog
          position={position}
          className="w-full max-w-lg p-input-filled text-center"
          header="Modify Your Reflector"
          headerStyle={{ paddingLeft: "4rem", paddingBottom: "0rem" }}
          visible={visible2}
          modal={true}
          style={{ width: "40rem" }}
          onHide={() => setVisible2(false)}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <EditRelationshipForm
            user={editingCircle}
            onSubmit={onRelationshipEditSubmit}
            onDelete={onDeleteRelationship}
          />
        </Dialog>

        <Dialog
          position={position}
          modal={true}
          className="w-full max-w-lg p-input-filled text-center"
          visible={selfEmailDialogVisible}
          onHide={() => setSelfEmailDialogVisible(false)}
          header="What will they see?">
          
          <SendSelfEmailForm
            setSelfEmailDialogVisible = {setSelfEmailDialogVisible}
            selfEmailButtonVisible = {selfEmailButtonVisible}
            setSelfEmailButtonVisible = {setSelfEmailButtonVisible}
            setSelfEmailConfirmDialogVisible = {setSelfEmailConfirmDialogVisible}
          >

          </SendSelfEmailForm>
        </Dialog>
        <Dialog
          position={position}
          modal={true}
          className="w-full max-w-lg p-input-filled text-center"
          visible={selfEmailConfirmDialogVisible}
          onHide={() => setSelfEmailConfirmDialogVisible(false)}
          header="Sample invitation sent!"
        >

          <SendSelfEmailCompletedForm
          setSelfEmailConfirmDialogVisible = {setSelfEmailConfirmDialogVisible}
          >

          </SendSelfEmailCompletedForm>
        </Dialog>
      </ReflectorsLayout>
    </Loading>
  );
}

export default Dashboard;
