// Important - make sure window.REACT_APP_API_BASE_URL is not set in code, but only via the ConfigMap
// See front-end-config.yaml for how this is configured for deployment in prod/staging etc.

// data:
//  config.js: |
//    window.REACT_APP_API_BASE_URL='https://app.mymirror360.com/api/'
// or:
// data:
//  config.js: |
//    window.REACT_APP_API_BASE_URL='https://app-staging.mymirror360.com/api/'
// etc.
// For local testing this can be defined in code, but this should not be merged in the repo:
// window.REACT_APP_API_BASE_URL = "https://localhost:7261/api/"
export const apiBaseUrl = window.REACT_APP_API_BASE_URL;

export const validateInviteCodeUrl = apiBaseUrl + "invites/valid";
export const forgotPasswordUrl = apiBaseUrl + "auth/forgotpassword";
export const resendForgotPasswordUrl =
  apiBaseUrl + "auth/resend-forgot-password-email";
export const resetPasswordUrl = apiBaseUrl + "auth/resetpassword";
export const getMyEmailUrl = apiBaseUrl + "auth/my-email";
export const setUserPassedToturialUrl =
  apiBaseUrl + "auth/passed-add-reflector-tutorial";
export const confirmEmailUrl = apiBaseUrl + "auth/confirm-email";
export const registerUrl = apiBaseUrl + "auth/register";
export const registerWithGoogleUrl = apiBaseUrl + 'auth/register-google';
export const registerWithLinkedInUrl = apiBaseUrl + 'auth/register-linkedin';
export const loginWithGoogleUrl = apiBaseUrl + 'auth/login-google';
export const loginWithLinkedInUrl = apiBaseUrl + 'auth/login-linkedin';
export const addGoogleLoginUrl = apiBaseUrl + 'auth/add-google';
export const addLinkedInLoginUrl = apiBaseUrl + 'auth/add-linkedin';
export const removeGoogleLoginUrl = apiBaseUrl + 'auth/remove-google';
export const removeLinkedInLoginUrl = apiBaseUrl + 'auth/remove-linkedin';
export const loginUrl = apiBaseUrl + "auth/login";
export const autoLoginUrl = apiBaseUrl + "auth/auto-login";
export const resendConfirmEmailUrl = apiBaseUrl + "auth/resend-register-email";
export const changePasswordUrl = apiBaseUrl + "auth/change-password";
export const unsubscribeUrl = apiBaseUrl + "auth/unsubscribe";
export const subscribeUrl = apiBaseUrl + "auth/subscribe";

export const relationshipGetEndpoint = apiBaseUrl + "relationships";
export const relationshipCreateEndpoint = apiBaseUrl + "relationships";
export const relationshipUpdateEndpoint = apiBaseUrl + "relationships";
export const relationshipDeleteEndpoint = apiBaseUrl + "relationships";
export const relationshipEmailExistsEndpoint =
  apiBaseUrl + "relationships/exists";

export const selfReflectionGetByTokenEndpoint = apiBaseUrl + "selfreflections";
export const submitSelfReflectionEndpoint = apiBaseUrl + "selfreflections";
export const getSelfAwarenessReportProgressEndpoint =
  apiBaseUrl + "selfreflections/progress";
export const isSelfAwarenessReportReadyEndpoint =
  apiBaseUrl + "selfreflections/is-ready";

export const performanceReflectionGetByTokenEndpoint =
  apiBaseUrl + "performancereflections";
export const submitPerformanceReflectionEndpoint =
  apiBaseUrl + "performancereflections";
export const performanceReflectionGetDataForCompleted =
  apiBaseUrl + "performancereflections/result";
export const performanceReflectionRejectEndpoint =
  apiBaseUrl + "performancereflections/reject";

export const getMyMirrorSurveyStatusEndpoint =
  apiBaseUrl + "users/survey-status";
export const toggleMyMirrorSurveyStatusEndpoint =
  apiBaseUrl + "users/survey-status";
export const getHasSentSelfEmailEndpoint = 
  apiBaseUrl + "users/sample-reflection-status";
export const setHasSentSelfEmailEndpoint = 
  apiBaseUrl + "users/sample-reflection-status";
export const sendSelfTestReflectionEmail =
  apiBaseUrl + "users/send-sample-reflection";

export const getPersonalInfoEndpoint = apiBaseUrl + "users/personal-info";
export const updatePersonalInfoEndpoint = apiBaseUrl + "users/personal-info";
export const getProfileInfoEndpoint = apiBaseUrl + "users/profile";
export const changeUserTimeZoneEndpoint = apiBaseUrl + "users/time-zone";
export const addOtherEmailEndpoint = apiBaseUrl + "users/other-email";
export const deleteOtherEmailEndpoint = apiBaseUrl + "users/other-email";
export const confirmOtherEmailEndpoint = apiBaseUrl + "users/other-email";
export const setOtherEmailAsPrimaryEndpoint =
  apiBaseUrl + "users/set-alias-as-primary";

export const getAppTimeZonesEndpoint = apiBaseUrl + "timezones";

export const getSelfAwarenessReportData = apiBaseUrl + "reports/self-awareness";
export const getQualitiveFeedbackEndpoint =
  apiBaseUrl + "reports/qualitive-feedback";
