import {Button} from "primereact/button"

function SendSelfEmailCompletedForm({setSelfEmailConfirmDialogVisible})
{
  return (
    <div>
      <div>
        Look for an email from Mirror 360 in your inbox. It will show you what people you add to your mirror see.
        Remember to come back and complete your mirror after that!
      </div>
      <div className="w-full flex justify-center" style={{marginTop:"20px"}}>
        <div className="flex gap-2" style={{position:"relative",margin:"auto"}}>
          <Button onClick={() => setSelfEmailConfirmDialogVisible(false)} >Close</Button>
        </div>
      </div>
    </div>
  );
}

export default SendSelfEmailCompletedForm;
