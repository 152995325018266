import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { sendSelfTestReflectionEmail, 
         setHasSentSelfEmailEndpoint } from "../../service/api-temp.js"
import { AppHttp } from "../../service/app-http";

function SendSelfEmailForm({
  setSelfEmailDialogVisible,
  setSelfEmailButtonVisible,
  setSelfEmailConfirmDialogVisible}) {

  const SendSelfEmailAction = async () => {
    try {
      const initialResponse = await AppHttp.post(sendSelfTestReflectionEmail);
      if (initialResponse.success) {
        console.log("success!");
  
        const finalResponse = await AppHttp.post(setHasSentSelfEmailEndpoint, 
          { hasSentSampleReflection: true }, 
          { headers: { 'Content-Type': 'application/json' }}
        );
  
        if (finalResponse.success) {
          console.log("success!");
          setSelfEmailButtonVisible(false);
          setSelfEmailDialogVisible(false);
          setSelfEmailConfirmDialogVisible(true);
        } else {
          console.error("error!");
          alert("Failed to send sample reflection email. Please try again.");
        }
      } else {
        console.error("failed endpoint call to send email");
        alert("Failed to initiate email sending. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  return (
  <div>
      <div>
        Send yourself a sample email invitation to reflect. This will show you exactly what your 
        reflectors will see.
     </div>
      <div className="w-full flex justify-center" style={{marginTop:"20px"}}>
        <div className="flex gap-2" style={{position:"relative",margin:"auto"}}>
          <Button
            onClick={() => setSelfEmailDialogVisible(false)}
            severity="secondary" 
          >
          Cancel
          </Button>

          <Button 
            onClick={SendSelfEmailAction}>Send</Button>
        </div>
      </div>
  </div>
);
}

export default SendSelfEmailForm;
